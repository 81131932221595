import { motion } from "framer-motion";
import React from "react";

export default function Loading() {
  return (<div className={"w-full h-[100vh] bg-gray-200 overflow-hidden flex justify-center items-center"}>
    <motion.div
    >
      <svg width="150" height="150" viewBox="0 0 323 262" fill="none" xmlns="http://www.w3.org/2000/svg">
        <motion.path
          d="M204.94 39.1599L216.2 31.2099C217.635 30.4797 219.251 30.1842 220.85 30.3599C221.467 30.4507 222.055 30.6787 222.571 31.0272C223.087 31.3756 223.519 31.8355 223.834 32.3731C224.149 32.9106 224.339 33.5119 224.39 34.1328C224.441 34.7536 224.353 35.378 224.13 35.9599C223.636 37.2998 222.802 38.4885 221.71 39.4099L210.31 47.2599L204.94 39.1599Z"
          fill="url(#paint0_linear_2252_972)" />
        <motion.path
          d="M206.93 37.7598L195.5 45.4498C194.299 46.5367 193.435 47.9459 193.01 49.5098C192.861 50.1184 192.851 50.7529 192.982 51.3658C193.113 51.9787 193.381 52.554 193.765 53.0486C194.15 53.5432 194.642 53.9442 195.204 54.2215C195.766 54.4988 196.384 54.6452 197.01 54.6498C198.428 54.6747 199.827 54.3301 201.07 53.6498L212.47 45.7798L206.93 37.7598Z"
          fill="url(#paint1_linear_2252_972)" />
        <motion.path
          d="M183.12 9.15042L194.37 1.21042C195.802 0.475074 197.42 0.179362 199.02 0.360419C199.642 0.447846 200.236 0.675378 200.758 1.02586C201.279 1.37635 201.714 1.84064 202.03 2.38372C202.346 2.9268 202.534 3.53451 202.581 4.16101C202.628 4.78751 202.532 5.41645 202.3 6.00042C201.805 7.34031 200.972 8.52899 199.88 9.45042L188.48 17.3104L183.12 9.15042Z"
          fill="url(#paint2_linear_2252_972)" />
        <motion.path
          d="M185.1 7.75L173.67 15.45C172.476 16.5188 171.612 17.9066 171.18 19.45C171.033 20.059 171.025 20.6933 171.156 21.3058C171.288 21.9184 171.556 22.4934 171.94 22.9883C172.324 23.4831 172.815 23.8849 173.376 24.1639C173.937 24.443 174.554 24.592 175.18 24.6C176.598 24.6325 178 24.2874 179.24 23.6L190.64 15.74L185.1 7.75Z"
          fill="url(#paint3_linear_2252_972)" />
        <motion.path
          d="M192.28 23.7198L213.44 9.23981C214.73 8.55981 218.28 6.76981 221.44 6.67981C225.75 6.54981 227.53 9.57981 225.32 13.5898C223.988 15.7344 222.277 17.6185 220.27 19.1498L198.89 33.5498L192.28 23.7198Z"
          fill="url(#paint4_linear_2252_972)" />
        <motion.path
          d="M196.13 21L174.92 35.4C173.81 36.35 170.85 39.01 169.6 41.94C167.91 45.94 170.07 48.69 174.6 48.11C177.076 47.6565 179.448 46.7551 181.6 45.45L202.81 30.8L196.13 21Z"
          fill="url(#paint5_linear_2252_972)" />
        <motion.path
          d="M116.05 146.77L136.65 184.11C126.682 197.961 117.856 212.599 110.26 227.88C107.98 232.53 104.87 239.23 98.0297 245.88C91.3836 252.359 83.1797 257.015 74.2097 259.4C58.4697 263.51 45.4997 259.01 39.4597 256.82C33.4197 254.63 18.2297 249.13 8.55971 233.65C-4.23029 213.17 2.26971 190.97 3.40971 187.3C39.1097 129.55 62.6797 90.4501 68.4097 77.8801C71.881 69.5479 77.4186 62.2378 84.4997 56.6401C86.7317 54.9476 89.0997 53.4425 91.5797 52.1401C100.66 47.3701 117.73 42.1401 136 48.2701C151.21 53.4001 159.77 63.9201 164.32 69.5101C168.182 74.2478 171.434 79.4519 174 85.0001C181.17 100.28 203.83 139.81 249.31 215C251.74 218.282 255.108 220.751 258.97 222.08C264.88 224.08 273.23 223.56 278.92 217.58C281.284 215.032 282.897 211.879 283.578 208.471C284.26 205.062 283.983 201.531 282.78 198.27L217.47 90.4701L203.07 72.4701L219.42 60.4701L235 48.6301L250.8 73.8001L318.16 186.68C322.613 196.569 323.743 207.635 321.38 218.22C318.18 232.01 309.81 240.22 305.93 243.97C298.794 250.599 290.194 255.451 280.83 258.13C277.08 259.22 254.4 265.42 233.83 253.62C226.941 249.576 221.012 244.087 216.45 237.53C191.32 202.77 141.66 106.4 137.93 99.1401C137.79 98.6701 134.63 88.8001 124.41 86.2701C114.19 83.7401 106.71 91.0901 106.41 91.4201L41.9997 197.62C41.2739 200.799 41.2739 204.101 41.9997 207.28C42.2497 208.35 45.3997 220.85 56.1597 222.73C62.5797 223.85 69.3497 220.73 73.5397 215L116.05 146.77Z"
          fill="url(#paint6_linear_2252_972)" />
        <defs>
          <linearGradient id="paint0_linear_2252_972" x1="213.413" y1="35.6344" x2="219.001" y2="44.1688"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="#3374BA" />
            <stop offset="0.35" stopColor="#1C8ED2" />
            <stop offset="0.75" stopColor="#08A5E7" />
            <stop offset="1" stopColor="#00AEEF" />
          </linearGradient>
          <linearGradient id="paint1_linear_2252_972" x1="203.906" y1="41.8808" x2="209.723" y2="50.1054"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="#3374BA" />
            <stop offset="0.35" stopColor="#1C8ED2" />
            <stop offset="0.75" stopColor="#08A5E7" />
            <stop offset="1" stopColor="#00AEEF" />
          </linearGradient>
          <linearGradient id="paint2_linear_2252_972" x1="191.585" y1="5.45052" x2="197.167" y2="13.9765"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="#3374BA" />
            <stop offset="0.35" stopColor="#1C8ED2" />
            <stop offset="0.75" stopColor="#08A5E7" />
            <stop offset="1" stopColor="#00AEEF" />
          </linearGradient>
          <linearGradient id="paint3_linear_2252_972" x1="181.961" y1="11.7606" x2="187.779" y2="19.9852"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="#3374BA" />
            <stop offset="0.35" stopColor="#1C8ED2" />
            <stop offset="0.75" stopColor="#08A5E7" />
            <stop offset="1" stopColor="#00AEEF" />
          </linearGradient>
          <linearGradient id="paint4_linear_2252_972" x1="206.133" y1="13.8258" x2="212.863" y2="24.1043"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="#3374BA" />
            <stop offset="0.35" stopColor="#1C8ED2" />
            <stop offset="0.75" stopColor="#08A5E7" />
            <stop offset="1" stopColor="#00AEEF" />
          </linearGradient>
          <linearGradient id="paint5_linear_2252_972" x1="183.545" y1="28.8258" x2="190.683" y2="38.9174"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="#3374BA" />
            <stop offset="0.35" stopColor="#1C8ED2" />
            <stop offset="0.75" stopColor="#08A5E7" />
            <stop offset="1" stopColor="#00AEEF" />
          </linearGradient>
          <linearGradient id="paint6_linear_2252_972" x1="0.749709" y1="153.29" x2="322.62" y2="153.29"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="#3374BA" />
            <stop offset="0.5" stopColor="#2D8DCC" />
            <stop offset="1" stopColor="#27AAE1" />
          </linearGradient>
        </defs>
      </svg>
      <motion.p className="text-black text-center">Loading ...</motion.p>
    </motion.div>
  </div>);
}