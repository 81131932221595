'use client';

import '@/styles/global.css';
import { Providers } from '@/app/providers';
import Header from '@/components/Header';
import React from 'react';
import ScrollToTop from '@/components/ScrollToTop';
import Loading from '@/components/Loading';
import { Auth, useAuth } from '@/hooks/useAuth';
//
// const geistSans = localFont({
//   src: "../assets/fonts/GeistMonoVF.woff",
//   variable: "--font-geist-sans",
//   weight: "100 900"
// });
//
// const geistMono = localFont({
//   src: "./fonts/GeistMonoVF.woff",
//   variable: "--font-geist-mono",
//   weight: "100 900"
// });
export default function RootLayout({
                                     children,
                                   }: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <html lang="en">
    <head>
      <title>MockMate</title>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href="/favicon.svg" />

    </head>
    <body className={`bg-[#FCFCFC] dark:bg-gray-dark`}
      // className={`${geistSans.variable} ${geistMono.variable} antialiased`}
    >
    <Providers>
      <MyApp>
        <Header />
        {children}
        <ScrollToTop />
      </MyApp>
    </Providers>
    </body>
    </html>
  );
}

function MyApp({ children }: Readonly<{ children: React.ReactNode }>) {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated === Auth.initial) {
    return <Loading />;
  }
  return children;

}