'use client';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { SetStateAction, useEffect, useState } from 'react';
import menuData from './menuData';
import { Auth, useAuth } from '@/hooks/useAuth';

const Header = () => {
  // Navbar toggle
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navbarToggleHandler = () => {
    setNavbarOpen(!navbarOpen);
  };

  // Sticky Navbar
  const [sticky, setSticky] = useState(false);
  const handleStickyNavbar = () => {
    if (window.scrollY >= 80) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleStickyNavbar);
  });

  // submenu handler
  const [openIndex, setOpenIndex] = useState(-1);
  const handleSubmenu = (index: SetStateAction<number>) => {
    if (openIndex === index) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };

  const usePathName = usePathname();

  const { isAuthenticated, authDetails, logout, profile } = useAuth();
  useEffect(() => {
  }, [profile, authDetails, isAuthenticated]);
  return (
    <>
      <header
        className={`header left-0 top-0 z-40 flex w-full items-center p-1 ${
          sticky
            ? 'dark:shadow-sticky-dark fixed z-[9999] bg-white !bg-opacity-80 shadow-sticky backdrop-blur-sm transition'
            : 'absolute bg-transparent'
        }`}
      >
        <div className="container">
          <div className="relative flex items-center justify-between">
            <div className="max-w-full  "
                 data-aos="zoom-in-up"
                 data-aos-delay="50"
                 data-aos-anchor-placement="top-bottom"
                 data-aos-duration="500"
            >
              <Link
                href="/"
                className={`flex items-center header-logo gap-2 w-full ${
                  sticky ? 'py-5 lg:py-2' : 'py-2'
                } `}
              >
                <Image
                  src="/images/logo/logo.svg"
                  alt="logo"
                  width={30}
                  height={10}
                  className="dark:hidden"
                />
                <Image
                  src="/images/logo/logo.svg"
                  alt="logo"
                  width={50}
                  height={10}
                  className="hidden dark:block"
                />
                <div
                  className="text-xl text-gray-dark font-bold  poppins-bold tracking-widest">MockMate
                </div>

              </Link>
            </div>
            <div className="flex w-full items-center justify-between px-4">
              <div>
                <button
                  onClick={navbarToggleHandler}
                  id="navbarToggler"
                  aria-label="Mobile Menu"
                  className="absolute right-4 top-1/2 block translate-y-[-50%] rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden"
                >
                  <span
                    className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${
                      navbarOpen ? ' top-[7px] rotate-45' : ' '
                    }`}
                  />
                  <span
                    className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${
                      navbarOpen ? 'opacity-0 ' : ' '
                    }`}
                  />
                  <span
                    className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${
                      navbarOpen ? ' top-[-8px] -rotate-45' : ' '
                    }`}
                  />
                </button>
                <nav
                  id="navbarCollapse"
                  className={`navbar absolute right-0 z-30 w-[250px] rounded border-[.5px] border-body-color/50 bg-white px-6 py-4 duration-300 dark:border-body-color/20 dark:bg-dark lg:visible lg:static lg:w-auto lg:border-none lg:!bg-transparent lg:p-0 lg:opacity-100 ${
                    navbarOpen
                      ? 'visibility top-full opacity-100'
                      : 'invisible top-[120%] opacity-0'
                  }`}
                >
                  <ul className="block lg:hidden lg:space-x-12">
                    {
                      isAuthenticated === Auth.authenticated ? <>

                          <li className="group relative"
                              data-aos="fade-right"
                              data-aos-delay="200"
                              data-aos-anchor-placement="top-bottom"
                              data-aos-duration="500">
                            <div
                              className="flex items-end gap-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
                                   viewBox="0 0 24 24">
                                <path fill="none" stroke="#000" strokeLinecap="round"
                                      strokeWidth="2"
                                      d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0Z" />
                              </svg>
                              <span
                                className="text-dark font-bold"> Credits {profile?.credits}</span>
                            </div>
                          </li>
                          <li className="mt-2 group relative"
                              data-aos="fade-right"
                              data-aos-delay="200"
                              data-aos-anchor-placement="top-bottom"
                              data-aos-duration="500">
                            <button
                              onClick={() => logout()}
                              className="ease-in-up shadow-btn hover:shadow-btn-hover rounded-md bg-primary px-4 py-1 text-base font-medium text-white transition duration-300 hover:bg-opacity-90 md:block md:px-4 lg:px-4 xl:px-4"
                            > Logout
                            </button>
                          </li>

                          {/*<div*/}
                          {/*  className="mx-2 relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">*/}
                          {/*  <svg xmlns="http://www.w3.org/2000/svg"*/}
                          {/*       viewBox="0 0 24 24">*/}
                          {/*    <circle cx="12" cy="8" r="4" fill="black" />*/}
                          {/*    <path fill="black"*/}
                          {/*          d="M5.338 17.32C5.999 14.528 8.772 13 11.643 13h.714c2.871 0 5.644 1.527 6.305 4.32c.128.541.23 1.107.287 1.682c.055.55-.397.998-.949.998H6c-.552 0-1.004-.449-.949-.998c.057-.575.159-1.14.287-1.681" />*/}
                          {/*  </svg>*/}
                          {/*</div>*/}
                        </>
                        : <div className={'flex flex-col'}><Link
                          href="/signin"
                          className=" px-7 py-3 text-base font-medium text-dark hover:opacity-70 dark:text-white md:block"
                        > Sign In </Link>
                          <Link
                            href="/signup"
                            className="ease-in-up shadow-btn hover:shadow-btn-hover  rounded-sm bg-primary px-8 py-3 text-base font-medium text-white transition duration-300 hover:bg-opacity-90 md:block md:px-9 lg:px-6 xl:px-9"
                          > Sign Up
                          </Link>
                        </div>
                    }
                    {menuData.map((menuItem, index) => (
                      <li key={index} className="group relative"
                          data-aos="fade-right"
                          data-aos-delay="200"
                          data-aos-anchor-placement="top-bottom"
                          data-aos-duration="500"
                      >

                        {menuItem.path ? (
                          <Link
                            href={menuItem.path}
                            className={`flex py-2 text-base lg:mr-0 lg:inline-flex lg:px-0 poppins-regular lg:py-6 gap-1 ${
                              usePathName === menuItem.path
                                ? 'text-primary dark:text-white'
                                : 'text-dark hover:text-primary dark:text-white/70 dark:hover:text-white'
                            }`}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"
                                 viewBox="0 0 24 24">
                              <path fill="currentColor"
                                    d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1" />
                            </svg>
                            {menuItem.title}
                          </Link>
                        ) : (
                          <>
                            <p
                              onClick={() => handleSubmenu(index)}
                              className="flex cursor-pointer items-center justify-between py-2 text-base text-dark group-hover:text-primary dark:text-white/70 dark:group-hover:text-white lg:mr-0 lg:inline-flex lg:px-0 lg:py-6"
                            >
                              {menuItem.title}
                              <span className="pl-3">
                                <svg width="25" height="24" viewBox="0 0 25 24">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </p>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              <div className="flex items-center justify-end pr-16 lg:pr-0">
                {
                  isAuthenticated === Auth.authenticated ? <>
                      <div
                        className="hidden lg:flex items-end gap-1 mr-2 bg-gray-100 border-gray-600 shadow px-4 py-1 rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
                             viewBox="0 0 24 24">
                          <path fill="none" stroke="#000" strokeLinecap="round"
                                strokeWidth="2"
                                d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0Z" />
                        </svg>
                        <span
                          className="text-dark font-bold"> Credits {profile?.credits}</span>
                      </div>
                      <button
                        onClick={() => logout()}
                        className="ease-in-up shadow-btn hover:shadow-btn-hover hidden rounded-md bg-primary px-4 py-1 text-base font-medium text-white transition duration-300 hover:bg-opacity-90 lg:block md:px-4 lg:px-4 xl:px-4"
                      > Logout
                      </button>

                      {/*<div*/}
                      {/*  className="mx-2 relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">*/}
                      {/*  <svg xmlns="http://www.w3.org/2000/svg"*/}
                      {/*       viewBox="0 0 24 24">*/}
                      {/*    <circle cx="12" cy="8" r="4" fill="black" />*/}
                      {/*    <path fill="black"*/}
                      {/*          d="M5.338 17.32C5.999 14.528 8.772 13 11.643 13h.714c2.871 0 5.644 1.527 6.305 4.32c.128.541.23 1.107.287 1.682c.055.55-.397.998-.949.998H6c-.552 0-1.004-.449-.949-.998c.057-.575.159-1.14.287-1.681" />*/}
                      {/*  </svg>*/}
                      {/*</div>*/}
                    </>
                    : <><Link
                      href="/signin"
                      className="hidden px-7 py-3 text-base font-medium text-dark hover:opacity-70 dark:text-white lg:block"
                    > Sign In </Link>
                      <Link
                        href="/signup"
                        className="ease-in-up shadow-btn hover:shadow-btn-hover hidden rounded-sm bg-primary px-8 py-3 text-base font-medium text-white transition duration-300 hover:bg-opacity-90 lg:block md:px-9 lg:px-6 xl:px-9"
                      > Sign Up
                      </Link>
                    </>
                }
                <div>
                  {/*<ThemeToggler/>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
