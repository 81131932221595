import { ThemeProvider } from "next-themes";
import React from "react";
import { AuthProvider } from "@/hooks/useAuth";
import { SnackbarProvider } from "notistack";

export function Providers({ children }: { children: React.ReactNode }) {
    return (
        <AuthProvider>
            <ThemeProvider attribute="class" enableSystem={false} defaultTheme="light">
                <SnackbarProvider>
                    {children}
                </SnackbarProvider>
            </ThemeProvider>
        </AuthProvider>
    );
}